import { defineStore } from 'pinia';

export const useFeatureFlagsStore = defineStore('featureFlagsStore', () => {
  const { $sentry } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();

  // NOTE: PostHog cookie used to create a watchable `distinctId`
  const posthogCookie = useCookie(`ph_${runtimeConfig.public.posthog.publicKey}_posthog`);
  const distinctId = computed((): string => {
    // @ts-ignore NOTE: useCookie automatically deserializes into JSON, it's just not being picked up by TypeScript: https://nuxt.com/docs/api/composables/use-cookie
    return posthogCookie.value?.distinct_id;
  });

  const featureFlags = useState<Record<string, string | boolean> | undefined>('ph-feature-flags');

  async function fetchFeatureFlags() {
    try {
      const flags = await $fetch('/api/feature-flags');
      featureFlags.value = flags;
    } catch (e) {
      $sentry?.captureException(e);
      featureFlags.value = {};
    }
  }

  watch(distinctId, async () => {
    await fetchFeatureFlags();
  });

  return {
    // state
    featureFlags,

    // actions
    init: fetchFeatureFlags,
  };
});

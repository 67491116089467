// aa is Algolia's conventional notation for Algolia Analytics
import aa from 'search-insights';

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();

  aa('init', {
    appId: runtimeConfig.public.algolia.applicationId,
    apiKey: runtimeConfig.public.algolia.apiKey,
  });

  return {
    provide: {
      insightsClient: aa,
    },
  };
});

<script setup lang="ts">
// NOTE: Adapted from known issue with HeadlessUI:
//   https://github.com/tailwindlabs/headlessui/issues/2913#issuecomment-1924681165
provideHeadlessUseId(() => useId().replace(/[-:]/, '_'));

const localeHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

const featureFlagsStore = useFeatureFlagsStore();

useHead(localeHead);
useAppSeoMeta();

onBeforeMount(async () => {
  await featureFlagsStore.init();
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
